import { ShowContainer } from "./AbstractShowStrategy";
import { AllwaysDesktopStrategy, DesktopStrategy } from "./carousel-show/DesktopStrategy";
import { MobileStrategy } from "./carousel-show/MobileStrategy";
import { ResponsiveFactory } from "./ResponsiveFactory";

export class CarouselShowStrategy extends ShowContainer {

    #responsiveFactory;

    constructor(withPause = false, useDesktopAllways = false, correctIndex = 0) {
        super();

        var desktopStrategy = new DesktopStrategy(withPause, correctIndex);
        if (useDesktopAllways) {
            desktopStrategy = new AllwaysDesktopStrategy(withPause);
        }
        this.#responsiveFactory = new ResponsiveFactory(desktopStrategy, new MobileStrategy(withPause));
    }

    show(target, contentUrlParameter) {
        if (target.closest('.carousel-item')) {
            this.#responsiveFactory.show(target, contentUrlParameter);
        } else {
            super.show(target, contentUrlParameter);
        }
    }
}