import { AbstractDesktopStrategy } from "../abstract/AbstractDesktopStrategy";
import { ScrollableShowStrategy } from "../ScrollableShowStrategy";

export class DesktopStrategy extends AbstractDesktopStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {
        var tabPane = target.closest('.tabs-content-pane-container');

        var tabContent = tabPane.closest('.tab-content');

        var tabPaneLabel = tabPane.getAttribute('aria-labelledby');

        var paneTrigger = document.getElementById(tabPaneLabel);

        if (paneTrigger) {

            bootstrap.Tab.getOrCreateInstance(paneTrigger).show();

            var cShow = new ScrollableShowStrategy();
            cShow.show(target, contentUrlParameter);
        }

        tabContent.scrollIntoView({behavior: "smooth", block: "center"});
        
    }
}