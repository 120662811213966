import { DocumentVisibilityData, DocumentVisibilityHandler } from "./DocumentVisibilityHandler";
import { FadeShowStrategy } from "./show-strategy/FadeShowStrategy";
import { FileDownloadShowStrategy } from "./show-strategy/FileDownloadShowStrategy";
import { ManifestShowStrategy } from "./show-strategy/ManifestShowStrategy";
import { PersonDataShowStrategy } from "./show-strategy/PersonDataShowStrategy";
import { ScrollableShowStrategy } from "./show-strategy/ScrollableShowStrategy";
import { ScrollIntoViewStrategy } from "./show-strategy/ScrollIntoViewStrategy";
import { SliderShowStrategy } from "./show-strategy/SliderShowStrategy";
import { TabShowStrategy } from "./show-strategy/TabShowStrategy";

export class SearchFinder {

    constructor() {

        history.scrollRestoration = "manual";

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const content = urlParams.get('content');

        // if must be supported in future
        // const title = urlParams.get('title');

        if (null !== content) {
            var target = document.querySelector(`[data-search-target = ${content}]`);

            if (target) {
                new DocumentVisibilityHandler(
                    new DocumentVisibilityData(this._getShowChain(), target, content)
                );
            } else {
                console.warn('Target not found! Please check if data-bs-target="'+content+'" exists.');
                
            }
        }
    }

    _getShowChain() {
        var strategy = new FadeShowStrategy();

        strategy.next(new TabShowStrategy())
            .next(new ScrollableShowStrategy())
            .next(new ManifestShowStrategy())
            .next(new PersonDataShowStrategy())
            .next(new FileDownloadShowStrategy())
            .next(new SliderShowStrategy())
            .next(new ScrollIntoViewStrategy());
        return strategy;
    }
}