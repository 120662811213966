import { AbstractVisibilityStrategy } from "./AbstractVisibilityStrategy";

export class VisibilityStrategyContainer extends AbstractVisibilityStrategy {
    #nextComponent;

    next(child) {
        this.#nextComponent = child;

        return child;
    }

    handleVisibility(handlerData) {
        if (this.#nextComponent) {
            this.#nextComponent.handleVisibility(handlerData);
        }
    }
}