import { ShowContainer } from "./AbstractShowStrategy";

export class FileDownloadShowStrategy extends ShowContainer {
    show(target, contentUrlParameter) {
        if (contentUrlParameter.includes('file_download')) {
            var primaryLink = target.closest('.primary-link');
            primaryLink.scrollIntoView({behavior: "smooth", block: "center"});
        } else {
            super.show(target, contentUrlParameter);
        }
    }
}