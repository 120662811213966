import { ShowContainer } from "./AbstractShowStrategy";
import { CarouselShowStrategy } from "./CarouselShowStrategy";

export class ManifestShowStrategy extends ShowContainer {
    show(target, contentUrlParameter) {
        if (contentUrlParameter.includes('manifest')) {

            var manifestBlock = target.closest('.manifest-block');

            var cShow = new CarouselShowStrategy(true, true);
            cShow.show(target, contentUrlParameter);

            manifestBlock.scrollIntoView({behavior: "smooth", block: "center"});
            
        } else {
            super.show(target, contentUrlParameter);
        }
    }
}