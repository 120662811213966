import { AbstractDesktopStrategy } from "../abstract/AbstractDesktopStrategy";

export class DesktopStrategy extends AbstractDesktopStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {
        var tabPane = target.closest('.tab-pane');

        if (tabPane) {
            var tabContent = tabPane.closest('.tab-content');
    
    
            var tabPaneLabel = tabPane.getAttribute('aria-labelledby');
            var paneTrigger = document.getElementById(tabPaneLabel);
    
            if (paneTrigger) {
                bootstrap.Tab.getOrCreateInstance(paneTrigger).show();
            }
    
            tabContent.scrollIntoView({behavior: "smooth", block: "center"});
        }

        var divScrollableContent = target.closest('.scrollable-content');

        if (divScrollableContent) {
            divScrollableContent.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }
}