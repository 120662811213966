export class BlockPaddingRight {

    onWindowResize = () => {

        var x = window.matchMedia("(min-width: 768px) and (max-width: 1919px)");

        if (!x.matches) {
            return;
        }

        var w = this.#interpolation(window.innerWidth);      
        document.documentElement.style.setProperty('--width-percentage', `${w}%` );
    }

    constructor() {
        window.onresize = this.onWindowResize;
        this.onWindowResize();
    }

    #interpolation(vw) {
        return 25 * ((vw - 768) / 1152);
    }
}