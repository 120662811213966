import { AbstractMobileStrategy } from "../abstract/AbstractMobileStrategy";

export class MobileStrategy extends AbstractMobileStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {
        var offDataSearchTarget = `offcanvas-${contentUrlParameter}`;

        var element = document.querySelector(`[data-search-target = ${offDataSearchTarget}]`);
        var offcanvas = element.closest('.offcanvas');

        bootstrap.Offcanvas.getOrCreateInstance(offcanvas).show();
    }
}