import { AbstractDesktopStrategy } from "../abstract/AbstractDesktopStrategy";

export class DesktopStrategy extends AbstractDesktopStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {
        var tabPane = target.closest('.tab-pane');
        var tabContent = tabPane.closest('.tab-content');

        if (tabContent.classList.contains('fade-content')) {

            var tabPaneLabel = tabPane.getAttribute('aria-labelledby');
            var paneTrigger = document.getElementById(tabPaneLabel);

            if (paneTrigger) {
                bootstrap.Tab.getOrCreateInstance(paneTrigger).show();
            }

            tabContent.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }
}