export class CollasibleContentHandler {

    constructor() {
        var tabTogglers = Array.from(document.querySelectorAll('[data-bs-toggle="tab"]'));

        tabTogglers.forEach((toggler) => {
            toggler.addEventListener('shown.bs.tab', (e) => {
                e.target.classList.remove('active');
            });
        });
    }
}