export class AbstractShowStrategy {

    show(target, contentUrlParameter) {
        throw new Error("Method 'show()' must be implemented.");
    }
}

export class ShowContainer extends AbstractShowStrategy {
    #nextComponent;

    next(child) {
        this.#nextComponent = child;

        return child;
    }

    show(target, contentUrlParameter) {
        if (this.#nextComponent) {
            this.#nextComponent.show(target, contentUrlParameter);
        }
    }
}