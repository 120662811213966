import { VisibilityStrategyContainer } from "./VisibilityStrategyContainer";

export class BackgroundTabStrategy extends VisibilityStrategyContainer {

    #handlerData;

    #listenerAdded = false;

    onVisibilityChange = (e) => {
        this.handleVisibility(this.#handlerData);

        if (this.#listenerAdded) {
            document.removeEventListener('visibilitychange', this.onVisibilityChange);
        }
    }

    handleVisibility(handlerData) {
        if ('hidden' === document.visibilityState) {
            if (!this.#listenerAdded) {
                this.#listenerAdded = true;
                document.addEventListener('visibilitychange', this.#setHandleData(handlerData).onVisibilityChange);
            }
            return;
        }

        super.handleVisibility(handlerData);
    }

    #setHandleData(handlerData) {
        this.#handlerData = handlerData;

        return this;
    }
}