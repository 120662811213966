import { ShowContainer } from "./AbstractShowStrategy";
import { ResponsiveFactory } from "./ResponsiveFactory";
import { DesktopStrategy } from "./scrollable-show/DesktopStrategy";
import { MobileStrategy } from "./scrollable-show/MobileStrategy";

export class ScrollableShowStrategy extends ShowContainer {
    #responsiveFactory;

    constructor() {
        super();
        this.#responsiveFactory = new ResponsiveFactory(
            new DesktopStrategy(),
            new MobileStrategy()
        );
    }

    show(target, contentUrlParameter) {
        if (contentUrlParameter.includes('scrollable_content')) {
            this.#responsiveFactory.show(target, contentUrlParameter);
        } else {
            super.show(target, contentUrlParameter);
        }
    }
}