import { ShowContainer } from "./AbstractShowStrategy";

export class AbstractResponsiveStrategy extends ShowContainer {

    #mediaQueryString;

    constructor(mediaQueryString) {
        super();

        this.#mediaQueryString = mediaQueryString;
    }

    show(target, contentUrlParameter) {
        if (window.matchMedia(this.#mediaQueryString).matches) {
            this._doShowForMediaQuery(target, contentUrlParameter);
        } else {
            super.show(target, contentUrlParameter);
        }
    }

    _doShowForMediaQuery(target, contentUrlParameter) {
        throw new Error("Method '_doShowForMediaQuery()' must be implemented.");
    }
}