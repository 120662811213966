export class CarouselContentHandler {

    constructor() {

        var carousels = Array.from(document.querySelectorAll('.carousel'));

        carousels.forEach((carousel) => {

            var carouselItems = Array.from(carousel.querySelectorAll('.carousel-item'));
    
            carouselItems.forEach((item, index) => {
                if (0 === index) {
                    item.classList.add('active');
                } else {
                    item.classList.remove('active');
                }
            });

            carousel.addEventListener('slide.bs.carousel', (e) => {
                var hideNextBy = carousel.getAttribute('data-hide-next-by');
                
                var from = e.from;
                var to = e.to;

                var controls = carousel.querySelector('.carousel-controls');

                if (!controls) {
                    return;
                }
                var prevControl = controls.querySelector('.carousel-prev');
                var nextControl = controls.querySelector('.carousel-next');
                
                if (0 === from) {
                    prevControl.classList.remove('d-none');
                }

                if (0 === to) {
                    nextControl.classList.remove('d-none');
                    prevControl.classList.add('d-none');

                    return;
                }

                if (0 !== from && 0 !== to && 'right' === e.direction) {
                    nextControl.classList.remove('d-none');
                    return;
                }

                var activeCarouselItem = carousel.querySelector('.carousel-item.active');
                // var x = window.matchMedia("(max-width: 767px)");
                // if (x.matches) {
                //     hideNextBy = null;
                // }

                if (null === hideNextBy) {
                    // hide next default strategy
                    var nextSiblingElement = activeCarouselItem?.nextElementSibling;
    
                    if (null == nextSiblingElement?.nextElementSibling) {
                        nextControl.classList.add('d-none');
                    } else {
                        var toElement = carouselItems[to];

                        if (null == toElement?.nextElementSibling) {
                            nextControl.classList.add('d-none');
                        }
                    }
                } else {
                    // hide next if activeIndex = length - hideByNext
                    var activeItemIndex = 0;
                    carouselItems.forEach((item, index) => {
                        if (item.classList.contains('active')) {
                            activeItemIndex = index;
                        }
                        
                        if ((carouselItems.length + parseInt(hideNextBy)) === (activeItemIndex + 1)) {
                            nextControl.classList.add('d-none');
                        }
                    });
                }
            });

        });
    }
}