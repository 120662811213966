import { ShowContainer } from "./AbstractShowStrategy";

export class PersonDataShowStrategy extends ShowContainer {

    show(target, contentUrlParameter) {
        if (contentUrlParameter.includes('person_content')) {
            var personData = target.closest('.person-data');
            personData.scrollIntoView({behavior: "smooth", block: "center"});
        } else {
            super.show(target, contentUrlParameter);
        }
    }
}