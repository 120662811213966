import "../styles/website-body.scss";

import { CollasibleContentHandler } from "./collasible-content/CollasibleContentHandler";
new CollasibleContentHandler();

import { CarouselContentHandler } from "./carousel-content/CarouselContentHandler";
new CarouselContentHandler();

import { BlockPaddingRight } from "./block-padding-right/BlockPaddingRight";
new BlockPaddingRight();

import { SearchFinder } from "./search-finder/SearchFinder";
new SearchFinder();
