import { ActiveTabStrategy } from "./visibility-strategy/ActiveTabStrategy";
import { BackgroundTabStrategy } from "./visibility-strategy/BackgroundTabStrategy";

export class DocumentVisibilityHandler {

    // @params handlerData: DocumentVisibilityData
    constructor(handlerData) {

        var visibilityChain = new BackgroundTabStrategy();
        visibilityChain.next(new ActiveTabStrategy());

        visibilityChain.handleVisibility(handlerData);
    }
}

export class DocumentVisibilityData{
    #chain;
    #target;
    #content;

    constructor(chain, target, content) {
        this.#chain = chain;
        this.#target = target;
        this.#content = content;
    }

    callShowAction() {
        this.#chain.show(this.#target, this.#content);
    }
}