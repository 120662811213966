import { AbstractDesktopStrategy } from "../abstract/AbstractDesktopStrategy";

export class DesktopStrategy extends AbstractDesktopStrategy {
    #withPause;
    #correctIndex;

    constructor(withPause = false, correctIndex = 0) {
        super();
        this.#withPause = withPause;
        this.#correctIndex = correctIndex;
    }

    _doShowForMediaQuery(target, contentUrlParameter) {
        var carouselItem = target.closest('.carousel-item');
        var carousel = target.closest('.carousel');

        carousel.scrollIntoView({block: "start", behavior: "smooth"}); 
        this.#slideTo(carouselItem, contentUrlParameter, carousel);
    }

    #slideTo(carouselItem, contentUrlParameter, carousel) {
        var inner = carouselItem.closest('.carousel-inner');
        var items = Array.from(inner.querySelectorAll('.carousel-item'));

        var filteredIndex = items.findIndex((item) => {
            return null !== item.querySelector(`[data-search-target = ${contentUrlParameter}]`);
        });

        var bsCarousel = bootstrap.Carousel.getOrCreateInstance(carousel);
        var toIndex = (filteredIndex + this.#correctIndex) > 0 ? filteredIndex + this.#correctIndex : 0;

        setTimeout(() => {
            bsCarousel.to(toIndex); 

            if (this.#withPause) {
                bsCarousel.pause();
            }
        }, 200);
    }
}

export class AllwaysDesktopStrategy extends DesktopStrategy {
    show(target, contentUrlParameter) {
        this._doShowForMediaQuery(target, contentUrlParameter);
    }
}