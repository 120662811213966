import { AbstractDesktopStrategy } from "../abstract/AbstractDesktopStrategy";
import { CarouselShowStrategy } from "../CarouselShowStrategy";

export class DesktopStrategy extends AbstractDesktopStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {        
        var listingBlock = target.closest('.slider-carousel');

        var cShow = new CarouselShowStrategy(true, true);
        cShow.show(target, contentUrlParameter);

        listingBlock.scrollIntoView({behavior: "smooth", block: "center"});
    }
}