import { AbstractMobileStrategy } from "../abstract/AbstractMobileStrategy";
import { CarouselShowStrategy } from "../CarouselShowStrategy";

export class MobileStrategy extends AbstractMobileStrategy {

    _doShowForMediaQuery(target, contentUrlParameter) {
        var listingBlock = target.closest('.slider-carousel');

        var cShow = new CarouselShowStrategy(true, true);
        cShow.show(target, contentUrlParameter);

        listingBlock.scrollIntoView({behavior: "smooth", block: "center"});
    }
}