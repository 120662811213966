import { ShowContainer } from "./AbstractShowStrategy";

export class ResponsiveFactory extends ShowContainer {

    // @var ShowContainer
    #handler;

    constructor(desktopHandler, mobileHandler) {
        super();

        desktopHandler.next(mobileHandler);
        this.#handler = desktopHandler;
    } 

    show(target, contentUrlParameter) {
        this.#handler.show(target, contentUrlParameter);
    }
}